import React from 'react';
import { getTournamentTitle } from '../utils/apiHelper';
import './page-title.css';

const PageTitle = ({ savedTournament }) => {
  const prefix = getTournamentTitle(savedTournament);

  return (
    <h1 className="page-title">{`${prefix} Prediction Leaderboard`}</h1>
  );
};

export default PageTitle;