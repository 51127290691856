import React from 'react';
import Select from 'react-select';
import './filter.css';

class FilterRow extends React.Component {
  sortByFilterChange = (e, sortCallback) => {
    if (e !== null) {
      sortCallback(e.value);
    } else {
      sortCallback('totalpoints');
    }
  };

  sortByFilterOrderChange = (e, orderCallback) => {
    if (e !== null) {
      orderCallback(e.value);
    } else {
      orderCallback('asc');
    }
  };

  render () {
    const {
      sortCallback,
      orderCallback,
    } = this.props;

    const sortByOptions = [
      { value: 'rank', label: 'Rank' },
      { value: 'matchpoints', label: 'Matches Won' },
      { value: 'bonuspoints', label: 'Bonus Points' },
      { value: 'totalpoints', label: 'Total Points' },
      { value: 'beststreak', label: 'Best Streak' },
      { value: 'accuracy', label: 'Win%' },
      { value: 'loss', label: 'Matches Lost' },
    ];

    const sortByOptionsOrder = [
      { value: 'asc', label: 'Low to High' },
      { value: 'desc', label: 'High to Low' },
    ];

    return (
      <div className="filter-row">
        <Select
          className="sort-by-filter"
          classNamePrefix="s"
          // defaultValue={sortByOptions[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          isSearchable={true}
          name="sort-by-filter"
          options={sortByOptions}
          placeholder='Select Sort'
          onChange={(e) => this.sortByFilterChange(e, sortCallback)}
        />
        <Select
          className="sort-by-filter-order"
          classNamePrefix="s"
          // defaultValue={sortByOptionsOrder[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isRtl={false}
          placeholder='Select Order'
          isSearchable={true}
          name="sort-by-filter-order"
          options={sortByOptionsOrder}
          onChange={(e) => this.sortByFilterOrderChange(e, orderCallback)}
        />
      </div>
    );
  }
};

export default FilterRow;
