import React from 'react';
import Loading from '../utils/loading';
import MatchCard from './match-card';
import { getGoogleDoc, checkCacheTS, setLastUpdatedComponent, getTournamentLogo } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import ToggleSwitch from '../utils/toggle';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      wait: true,
    };
  }

  componentDidMount() {
    const { savedTournament } = this.props;

    // Check Local Storage.
    const cacheStatus = checkCacheTS('schedule_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('schedule');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        this.setState({
          schedule: resultsUnwrap,
          wait: false,
        });
        setLastUpdatedComponent(localStorage.getItem('schedule_last_updated'));
      }
    } else {
      getGoogleDoc('Schedule', savedTournament).then((response) => {
        this.setState({
          schedule: response,
          wait: false,
        });
        // Local Storage set.
        localStorage.setItem('schedule', stringify(response));
        localStorage.setItem('schedule_last_updated', Date.now());
        setLastUpdatedComponent(localStorage.getItem('schedule_last_updated'));
      });
    }
  }

  getMatchCards = (schedule) => {
    const matchList = [];
    const { savedTournament } = this.props;

    const logoClass = getTournamentLogo(savedTournament);

    Object.entries(schedule).forEach(([index, match]) => {
      matchList.push(
        <MatchCard
          logoClass={logoClass}
          key={`react-key-${index}`}
          date={match.date}
          day={match.day}
          time={match.time}
          matchNumber={match.matchnumber}
          matchName={match.matchname}
          venue={match.venue}
          city={match.city}
          team1={match.team1_flag}
          team2={match.team2_flag}
          won={match.result}
        />,
      );
    });

    return matchList;
  }

  render() {
    const {
      wait,
      schedule,
    } = this.state;

    if (wait === true) {
      return (
        <div className="schedule-table waiting">
          <Loading />
        </div>
      )
    }
    return (
      <>
        <div className="schedule-header">
          <ToggleSwitch label="Show only future matches" />
        </div>
        <div className="schedule-table">
          {this.getMatchCards(schedule)}
        </div>
      </>
    );
  }
};

export default Schedule;
