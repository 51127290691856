import React from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { getGoogleDoc, checkCacheTS } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import Loading from '../utils/loading';
class WinChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: true,
      pSeries: [],
      pOptions: {},
      wSeries: [],
      wOptions: {},
    }
  }

  componentDidMount() {
    const { savedTournament } = this.props;
    // Check Local Storage.
    const cacheStatus = checkCacheTS('results_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('results');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        this.getCleanData(resultsUnwrap);
      }
    } else {
      getGoogleDoc('Results', savedTournament).then((response) => {
        this.getCleanData(response);

        localStorage.setItem('results', stringify(response));
        localStorage.setItem('results_last_updated', Date.now());
      });
    }
  }

  getCleanData = (results) => {
    const { colorMode } = this.props;
    let name = [];
    let wName = [];
    let winPercentage = [];
    let matchPoints = [];
    let bonusPoints = [];
    Object.entries(results).forEach(([key, result]) => {
      if (result.matchpoints > 0) {
        name.push(result.name);
        matchPoints.push(result.matchpoints);
        bonusPoints.push(parseFloat(result.bonuspoints).toFixed(2))
      }
    });

    const resultsSortedAccuracy = _.sortBy(results, function (obj) {
      return parseFloat(obj['accuracy']);
    }).reverse();

    Object.entries(resultsSortedAccuracy).forEach(([key, result]) => {
      if (result.matchpoints > 0) {
        wName.push(result.name);
        winPercentage.push(result.accuracy);
      }
    });

    console.log(wName);
    console.log(winPercentage);

    const defaultColor = colorMode === true ? '#222' : '#57b8ff';

    this.setState({
      pSeries: [
        {
          name: 'Match Points',
          data: matchPoints
        },
        {
          name: 'Bonus Points',
          data: bonusPoints
        }
      ],
      pOptions: {
        chart: {
          type: 'bar',
          height: 600,
          stacked: true,
          foreColor: defaultColor,
        },
        colors:['#008FFB', '#2176ae'],
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Quicksand',
            fontWeight: 'normal',
          },
        },
        stroke: {
          show: false,
        },
        xaxis: {
          categories: name,
          axisBorder: {
            color: defaultColor,
          },
          axisTicks: {
            color: defaultColor,
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
          axisBorder: {
            color: defaultColor,
          },
          axisTicks: {
            color: defaultColor,
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: 40
        }
      },
      wSeries: [{
        name: 'Winning %',
        data: winPercentage,
      }],
      wOptions: {
        chart: {
          type: 'bar',
          height: 650,
          foreColor: defaultColor,
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: wName,
          axisBorder: {
            color: defaultColor,
          },
          axisTicks: {
            color: defaultColor,
          }
        },
        yaxis: {
          max: 100,
          axisBorder: {
            color: defaultColor,
          },
          axisTicks: {
            color: defaultColor,
          }
        },
      },
      wait: false,
    });
  };

  render() {
    const {
      wait,
      pSeries,
      pOptions,
      wSeries,
      wOptions,
    } = this.state;

    if (wait === true) {
      return (
        <>
          <div className="chart-waiting">
            <Loading />
          </div>
          <div className="chart-waiting">
            <Loading />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="total-points-chart a-chart">
          <h2>Total Points (match point + bonus point)</h2>
          <Chart
            options={pOptions}
            series={pSeries}
            type="bar"
            width="100%"
            height="650"
          />
        </div>
        <div className="win-percentage-chart a-chart">
          <h2>Prediction Accuracy (%)</h2>
          <Chart
            options={wOptions}
            series={wSeries}
            type="bar"
            width="100%"
            height="650"
          />
        </div>
      </>
    );
  }
}

export default WinChart;
