import React, { useEffect, useRef } from 'react';
import uPlot from 'uplot';
import './uPlot.min.css';

function MyPlot(props) {
  const plotRef = useRef();

  useEffect(() => {
    const {options, data} = props;
    new uPlot(options, data, plotRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={plotRef} />
  );
}

export default MyPlot;
