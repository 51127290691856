import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const TeamMember = (props) => {
  const {
    name,
    linkedin,
    photo,
    animateClass,
  } = props;

  return (
    <div style={{animationDelay: `0.4s`}} className={`team-member animate__animated ${animateClass}`}>
      <a target="_blank" rel="noreferrer" href={linkedin}>
        <div className="user-image">
          <img alt={name} src={photo} loading="lazy" />
        </div>
        <h3>
          <FontAwesomeIcon icon={faLink} />
          {name}
        </h3>
      </a>
    </div>
  );
};

export default TeamMember;
