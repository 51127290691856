import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const TOAST_DURATION = 4500;
const TOAST_POSITION = 'top-right';

const options = {
  position: TOAST_POSITION,
  autoClose: TOAST_DURATION,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
};

export const notify = (message, type) => {
  if (type === 'info') {
    toast.info(message, options);
  }
  else if (type === 'error') {
    toast.error(message, options);
  }
  else if (type === 'warning') {
    toast.warn(message, options);
  }
  else {
    toast.success(message, options);
  }
};
