import React from 'react';
import Switch from 'react-switch';
import './toggle.css';

class ToggleSwitch extends React.Component {
  constructor() {
    super();
    this.state = { checked: true };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (checked) => {
    this.setState({ checked: false });
    document.getElementsByClassName('schedule-header')[0].classList.toggle('show-past');
  };

  render () {
    const {
      label
    } = this.props;

    return (
      <div className="toggleSwitch">
        <span>{label}</span>
        <Switch 
          checked={this.state.checked}
          onChange={this.handleChange}
          onColor="#ccc"
          onHandleColor="#fc4c02"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
        />
      </div>
    );
  }
};

export default ToggleSwitch;
