import React from 'react';
import {
  Route,
  Link,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faStar, faTrophy, faFire, faCrown, faFrown, faCalculator } from '@fortawesome/free-solid-svg-icons';
import './results-row.css';
import ResultIndividualUser from '../result-individual';
import 'animate.css';
import Avatar from './user-avatar';

const getFormMarkup = (lastFive) => {
  let form = [];
  if (lastFive !== undefined) {
    const rawData = lastFive.split(',');
    rawData.forEach((result, index) => {
      form.push(
        <span key={index} className={`${result.toLowerCase()} highlight`}>{result.toUpperCase()}</span>
      );
    });
  } else {
    // Empty data.
  }

  return form;
};

const ResultsRow = (props) => {
  const {
    name,
    won,
    loss,
    bonus,
    lastFive,
    accuracy,
    best,
    id,
    imageurl,
    rank,
    animationDelayValue,
    totalPoints,
  } = props;

  const total = totalPoints;

  return (
    <>
      <div style={{animationDelay: `${animationDelayValue}s`}} className={`results-row animate__animated animate__fadeIn`}>
        <div className="avatar">
          <Avatar name={name} imageurl={imageurl} />
        </div>
        <div className="name">
          <Link to={`/user/${id}`}>{name}</Link>
        </div>
        {/* <div className="played">{played ? played : 0}</div> */}
        <div className="form">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faArrowRight} />
            <span>Last 5</span>
          </span>
          <span className="last-5">{getFormMarkup(lastFive)}</span>
        </div>
        <div className="item item-w won">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faCrown} />
          </span>
          <span className="w skew highlight">{won ? parseFloat(won).toFixed(2) : 0}</span>
        </div>
        <div className="item item-l lost">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faFrown} />
          </span>
          <span className="l skew highlight">{loss}</span>
        </div>
        <div className="best-streak">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faFire} />
          </span>
          {best ? best : 0}
        </div>
        <div className="win-percentage">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faCalculator} />
          </span>
          {`${accuracy}`}
        </div>
        <div className="bonus">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faStar} />
          </span>
          <span className="b skew highlight">{parseFloat(bonus).toFixed(2)}</span>
        </div>
        <div className="total-points">
          <span className="mobile-only-label">
            <FontAwesomeIcon icon={faTrophy} />
          </span>
          <span className="t skew highlight">{total}</span>
        </div>
        <div className="position" title="Rank">
          <span title="Overall Rank" className={`pos pos-${rank}`}>{rank}</span>
        </div>
      </div>
      <Route path="/user/:id/">
        <ResultIndividualUser />
      </Route>
    </>
  )
};

export default ResultsRow;
