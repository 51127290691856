import React from 'react';
import WinChart from './win-percentage';
import './charts.css';
import ProgressChart from './progression';
import MessageBanner from '../offline-banner';

const ChartsGraphics = ({ colorMode, savedTournament }) => (
  <>
    <MessageBanner classes="chart-help">
      This Page is best viewed on desktop & tablet devices. Some features are restricted due to lack of screen space.
    </MessageBanner>
    <div className="charts">
      <ProgressChart savedTournament={savedTournament} colorMode={colorMode} />
      <WinChart savedTournament={savedTournament} colorMode={colorMode} />
    </div>
  </>
);

export default ChartsGraphics;
