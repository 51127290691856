import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTrophy, faDice, faCrown, faFrown, faCalculator } from '@fortawesome/free-solid-svg-icons';

const UserTitleStats = ({bonuspoints, matchpoints, totalmatch, loss, accuracy, totalpoints, triviapoints, rank}) => {
  const getRankingText = (rank) => {
    if (parseInt(rank) === 1) {
      return (
        <span className={`pos-${parseInt(rank)} animate__animated animate__bounceInRight`}>
          <span>{rank}</span>
          {/* <span>st</span> */}
        </span>
      );
    }
    else if (parseInt(rank) === 2) {
      return (
        <span className={`pos-${parseInt(rank)} animate__animated animate__bounceInRight`}>
          <span>{rank}</span>
          {/* <span>nd</span> */}
        </span>
      );
    }
    else if (parseInt(rank) === 3) {
      return (
        <span className={`pos-${parseInt(rank)} animate__animated animate__bounceInRight`}>
          <span>{rank}</span>
          {/* <span>rd</span> */}
        </span>
      );
    }
    else {
      return (
        <span className={`pos-${parseInt(rank)} animate__animated animate__bounceInRight`}>
          <span>{rank}</span>
          {/* <span>th</span> */}
        </span>
      );
    }
  };

  return (
    <>
      <div className="stats">
        <div className="total-points">
          <span className="label">
            <FontAwesomeIcon icon={faTrophy} />
            Total
          </span>
          <span className="t skew highlight">{totalpoints}</span>
        </div>
        <div className="total-points trivia-points">
          <span className="label">
            <FontAwesomeIcon icon={faDice} />
            Trivia
          </span>
          <span className="trivia skew highlight">{triviapoints}</span>
        </div>
        <div className="item item-w won">
          <span className="label">
            <FontAwesomeIcon icon={faCrown} />
            Won
          </span>
          <span className="w skew highlight">{matchpoints ? matchpoints : 0}</span>
        </div>
        <div className="bonus">
          <span className="label">
            <FontAwesomeIcon icon={faStar} />
            Bonus
          </span>
          <span className="b skew highlight">{bonuspoints ? bonuspoints : 0}</span>
        </div>
        <div className="item item-l lost">
          <span className="label">
            <FontAwesomeIcon icon={faFrown} />
            Lost
          </span>
          <span className="l skew highlight">{loss ? loss : 0 }</span>
        </div>
        <div className="accuracy">
          <span className="label">
            <FontAwesomeIcon icon={faCalculator} />
            Accuracy
          </span>
          <span className="acc skew highlight">{accuracy ? accuracy + '%' : '0%' }</span>
        </div>
      </div>
      <div className="rank">
        { getRankingText(rank) }
      </div>
    </>
  );
};

export default UserTitleStats;
