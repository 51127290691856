import React from 'react';
import { getTournamentList, checkActiveTournament } from '../utils/apiHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { notify } from '../utils/toast';
import './tournaments.css';

const tournamentOption = 'tournamentOptionRadio';

class Tournaments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: '',
    };
  }

  onChange = (e) => {
    const { setTournamentId } = this.props;
    const value = e.target.value;
    
    // Check if active tournament.
    const valid = checkActiveTournament(value);

    if (valid === 'yes' || window.location.hostname === 'localhost') {
      localStorage.setItem(tournamentOption, value);
      this.setState({
        option: value,
      });
  
      // Set in parent element state.
      setTournamentId(value);
    } else {
      notify('This tournment will be starting soon! Patience is a virtue.');
    }
  };

  getMarkup = () => {
    const optionList = [];
    const tList = getTournamentList();

    // See if a option is set.
    const { savedTournament } = this.props;
    
    tList.forEach((option, index) => {
      optionList.push(
        <div className="tournament-option" key={option.id}>
          <input
            type="radio"
            id={option.id}
            name="tournament"
            value={option.id}
            checked={savedTournament === option.id ? "checked" : false}
            onChange={(e) => this.onChange(e)}
            title={option.pageTitle}
          />
          <label htmlFor={option.id}>
            <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            {option.name}
          </label>
        </div>
      );
    });

    return optionList;
  }
  
  render () {
    return (
      <div className="tournament-switch">
        {this.getMarkup()}
      </div>
    );
  }
};

export default Tournaments;