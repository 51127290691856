import React from 'react';
import ResultsRow from './results-row';
import HeaderRow from './header-row';
import Loading from '../utils/loading';
import EmptyRow from './empty-row';
import { getGoogleDoc, checkCacheTS, setLastUpdatedComponent } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import _ from 'lodash';
import FilterRow from './filter-row';

class ResultsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      wait: true,
      sortFilter: 'totalpoints',
      sortOrder: 'desc'
    };
  }

  setSortFilter = (filter) => {
    console.log(filter);
    this.setState({
      sortFilter: filter,
    });
  };

  setSortOrder = (order) => {
    console.log(order);
    this.setState({
      sortOrder: order,
    });
  };

  componentDidMount() {
    const { savedTournament, setPodium } = this.props;

    // Check Local Storage.
    const cacheStatus = checkCacheTS('results_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('results');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        this.setState({
          results: resultsUnwrap,
          wait: false,
        });
        setPodium(resultsUnwrap);
        setLastUpdatedComponent(localStorage.getItem('results_last_updated'));
      }
    } else {
      getGoogleDoc('Results', savedTournament).then((response) => {
        this.setState({
          results: response,
          wait: false,
        });

        setPodium(response);
        localStorage.setItem('results', stringify(response));
        localStorage.setItem('results_last_updated', Date.now());
        setLastUpdatedComponent(localStorage.getItem('results_last_updated'));
      });
    }
  }

  generateResults = () => {
    const {
      results,
      sortFilter,
      sortOrder,
    } = this.state;

    const resultList = [];
    let animationIncrement = 0;
    let atleastOnePlayed = false;

    // const resultsArray = Object.entries(results);
    let resultsSorted = null;

    // Set filter and sort.
    if (sortOrder === 'asc') {
      resultsSorted = _.sortBy(results, function (obj) {
        return parseFloat(obj[sortFilter]);
      });
    } else {
      resultsSorted = _.sortBy(results, function (obj) {
        return parseFloat(obj[sortFilter]);
      }).reverse();
    }

    resultsSorted.forEach((result) => {
      if (result.totalmatch > 0) {
        atleastOnePlayed = true;
        let animationDelayValue = (0.3 + (animationIncrement)).toFixed(2);
        // If player key is missing, add a backup key.
        let uniqueURLKey = result.playerkey === null || result.playerkey === 'undefined' || result.playerkey.length === 0
          ? result.rank + result.slackuid
          : result.playerkey;

        resultList.push(
          <ResultsRow
            name={result.name}
            played={result.totalmatch}
            won={result.matchpoints}
            loss={result.loss}
            totalPoints={result.totalpoints}
            bonus={result.bonuspoints}
            lastFive={result.last5}
            best={result.beststreak}
            id={uniqueURLKey}
            imageurl={result.imageurl}
            rank={result.rank}
            key={result.name}
            accuracy={result.accuracy}
            animationDelayValue={animationDelayValue}
          />,
        );
        animationIncrement=animationIncrement+0.1;
      }
    });

    if (!atleastOnePlayed) {
      resultList.push(
        <EmptyRow />
      );
    }

    return resultList;
  }

  render() {
    const {
      wait,
    } = this.state;

    if (wait === true) {
      return (
        <div className="results-table">
          <Loading />
        </div>
      )
    }

    return (
      <div className="results-table">
        <FilterRow
          sortCallback={this.setSortFilter}
          orderCallback={this.setSortOrder}
        />
        <HeaderRow />
        { this.generateResults() }
      </div>
    );
  }
}

export default ResultsTable;
