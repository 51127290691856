import { GoogleSpreadsheet } from 'google-spreadsheet';

export const getGoogleDoc = async (sheetTitle, savedTournament) => {
  // Initialize the sheet - doc ID is the long id in the sheets URL
  const sheetId = getTournamentSheet(savedTournament);
  const doc = new GoogleSpreadsheet(sheetId);
  await doc.useServiceAccountAuth({
    private_key: process.env.REACT_APP_KEY.replace(/\\n/g, '\n'),
    client_email: process.env.REACT_APP_EMAIL,
  });

  // loads document properties and worksheets.
  await doc.loadInfo();
  // Load sheet.
  const resultsSheet = doc.sheetsByTitle[sheetTitle];
  const rows = await resultsSheet.getRows();
  return rows;
};

export const getWinPercentage = (won, bonus, played) => {
  let totalPoints = 0;
  let bonusPoints = 0;
  if (parseFloat(bonus) > 0) {
    bonusPoints = 1;
  }
  totalPoints = bonusPoints + parseInt(won);
  // Each match has two predictions.
  const totalPlayed = parseInt(played) * 2;
  return (totalPoints/totalPlayed * 100).toFixed(1);
};

// Max cache.
// 6hr expiry.
const DATA_HOLD = 21600000;

// Interval cache.
// 15min expiry.
const SMALL_DATA_HOLD = 900000

/**
 * Check Stored TS.
 *
 * @param {*} key
 *  Storage key.
 *
 * @returns {string}
 */
export const checkCacheTS = (key) => {
  // Bypass cache and force data renewal.
  const params = new URLSearchParams(window.location.search);
  const spell = params.get('spell');
  if (spell === 'reparo') {
    console.log('reparo');
    return 'expired';
  }
  // Get current date.
  const date = new Date();
  // Get The last updated TS.
  const updateTS = localStorage.getItem(key);
  // If key has been removed or not present.
  if (updateTS === null) {
    return 'expired';
  }
  // Check if we are in the 23rd hour and result is expected soon.
  // In this case we reduce the hold to 15min.
  if (date.getHours() === 23) {
    const shortInterval = Date.now() - updateTS;
    // 15min hold on data.
    if (shortInterval > SMALL_DATA_HOLD) {
      // Time elapsed, fetch new data.
      return 'expired';
    }
    // Not yet, continue hold.
    return 'toosoon';
  }

  // Normal flow .
  const interval = Date.now() - updateTS;
  // 6hr hold on data.
  if (interval > DATA_HOLD) {
    // Time elapsed, fetch new data.
    return 'expired';
  }
  // Not yet, continue hold.
  return 'toosoon';
};

/**
 * Get getColorMode from localstorage.
 *
 * @returns {*} getColorMode
 */
export const getColorMode = () => {
  return localStorage.getItem('darkModeCheckbox');
};

/**
 * Set the last updated date string in DOM.
 *
 * @param {*} epoch
 *   Timestamp
 */
export const setLastUpdatedComponent = (epoch) => {
  const date = new Date(parseInt(epoch));
  document.getElementsByClassName('last-updated')[0].classList.remove('hide');
  document.getElementById('last-updated-value').innerHTML = 'Synced on ' + date;
};

/**
 * Get list of tounrmanets we have played game for.
 */
export const getTournamentList = () => {
  return [
    {
      "name": "T20 WC 2024",
      "pageTitle": "T20 WC 2024",
      "id": "t20wc-2024",
      "logo": "wc",
      "sheet": "1tvKU0m1nrAM9cHmBkJnhzqFVNkxw83-JjPWRpDcdhfo",
      "active": "yes",
      "podium": false,
    },
    {
      "name": "IPL 2024",
      "pageTitle": "IPL 2024",
      "id": "ipl-2024",
      "logo": "ipl",
      "sheet": "16Ab951IHAtZq08Omh5Z_w2ucO6bBT832br7pK9mtjuo",
      "active": "yes",
      "podium": true,
    },
    {
      "name": "ODI WC 2023",
      "pageTitle": "ODI WC 2023",
      "id": "odiwc-2023",
      "logo": "wc",
      "sheet": "17npLqdAbIJbUPxmVQJCHJPyvWYwov3J5ZxfcoRHYzg8",
      "active": "yes",
      "podium": true,
    },
    // {
    //   "name": "IPL 2023",
    //   "pageTitle": "IPL 2023",
    //   "id": "ipl-2023",
    //   "logo": "ipl",
    //   "sheet": "1giA3H8B5heu8-WSBxhrfaEj5_PFo99VXPHMh5FbCO-0",
    //   "active": "yes",
    //   "podium": true,
    // },
    // The sheet structure is now incompatible with the code for below tournaments.
    // {
    //   "name": "IPL 2022",
    //   "pageTitle": "IPL 2022",
    //   "id": "ipl-2022",
    //   "logo": "ipl",
    //   "sheet": "1BiM6ZDux1REaB3xmBjlSTBF2b8wvBMc85v6p9xrbZJA",
    //   "active": "yes",
    //   "podium": true,
    // },
    // {
    //   "name": "T20 WC 2022",
    //   "pageTitle": "T20 World Cup 2022",
    //   "id": "t20wc-2022",
    //   "logo": "t20wc",
    //   "sheet": "1ZmUb6AwIXZe0nSGO2NjnQCIUU03Iq0Q2PPL7kLW_xws",
    //   "active": "yes",
    //   "podium": false,
    // },
    // {
    //   "name": "IPL 2021",
    //   "pageTitle": "IPL 2021",
    //   "id": "ipl-2021",
    //   "logo": "ipl",
    //   "sheet": "1i5Dlsq0SzskJurQtEjDW0JzT7_eQI36S6HUgbhLioj0",
    //   "active": "yes",
    //   "podium": true,
    // },
    // {
    //   "name": "T20 WC 2021",
    //   "pageTitle": "T20 World Cup 2021",
    //   "id": "t20wc-2021",
    //   "logo": "t20wc",
    //   "sheet": "141Cox0VQswpoONN69israL9drFsB1STdXJEbG3kiIro",
    //   "active": "yes",
    //   "podium": true,
    // },
  ];
};

/**
 * Get saved tournmanet in local storage.
 */
 export const getSavedTournament = () => {
  const tournamentOption = 'tournamentOptionRadio';
  // Either fetch saved option or 1st option.
  const value = localStorage.getItem(tournamentOption);
  if (value) {
    return value;
  } else {
    const tList = getTournamentList();
    return tList[0].id;
  }
};

/**
 * Get Tournament title.
 * 
 * @param {string} savedTournament 
 * 
 * @returns {string}
 */
export const getTournamentTitle = (savedTournament) => {
  const tList = getTournamentList();
  let prefix = false;
  tList.forEach((element) => {
    if (savedTournament === element.id) {
      prefix = element.pageTitle;
    }
  });

  return prefix;
}

/**
 * Get Tournament Logo.
 * 
 * @param {string} savedTournament 
 * 
 * @returns {string}
 */
 export const getTournamentLogo = (savedTournament) => {
  const tList = getTournamentList();
  let prefix = false;
  tList.forEach((element) => {
    if (savedTournament === element.id) {
      prefix = element.logo;
    }
  });

  return prefix;
}

/**
 * Get Tournament Active flag.
 * 
 * @param {string} id of tournament. 
 * 
 * @returns {string}
 */
export const checkActiveTournament = (tid) => {
  const tList = getTournamentList();
  let active = false;
  tList.forEach((option) => {
    if (tid === option.id) {
      active = option.active;
    }
  });

  return active;
}

/**
 * Get Tournament Podium flag.
 * 
 * @param {string} savedTournament 
 * 
 * @returns {string}
 */
 export const showPodium = (savedTournament) => {
  const tList = getTournamentList();
  let showPodiumFlag = false;
  tList.forEach((element) => {
    if (savedTournament === element.id) {
      showPodiumFlag = element.podium;
    }
  });

  return showPodiumFlag;
}

/**
 * Get Tournament sheet.
 * 
 * @param {string} savedTournament 
 * 
 * @returns {string}
 */
 export const getTournamentSheet = (savedTournament) => {
  const tList = getTournamentList();
  let sheet = false;
  tList.forEach((element) => {
    if (savedTournament === element.id) {
      sheet = element.sheet;
    }
  });

  return sheet;
}

/**
 * Remove all localStorage keys.
 */
export const resetLocalStorage = () => {
  localStorage.removeItem('progression_last_updated');
  localStorage.removeItem('results_last_updated');
  localStorage.removeItem('individual_last_updated');
  localStorage.removeItem('schedule_last_updated');
}