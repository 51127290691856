import React from 'react';
import FAQItem from './faq-item';
import './faq.css';

const FAQ = () => {
  const lastUpdated = document.getElementsByClassName('last-updated');
  if (lastUpdated.length > 0) {
    lastUpdated[0].classList.add('hide');
  }
  return (
    <div className="faq-content">
      <FAQItem
        question="How can I participate?"
        answer="You can participate via the #guild-cricket channel, look out for bot announcements."
        animationDelayValue="0.4"
      />
      <FAQItem
        question="I have missed some matches can I still start playing?"
        answer="Yes, You can join any time."
        animationDelayValue="0.5"
      />
      <FAQItem
        question="I am busy with work, how much time investment is needed to play?"
        answer="The game is designed to take less than 20 sec of your time &#128525; . All you need is 'One click' for each prediction!"
        animationDelayValue="0.6"
      />
      <FAQItem
        question="What is the format of the game?"
        answer="For every match, we will ask you to predict the winner of the match along with a bonus question. Predict accurately and win."
        animationDelayValue="0.7"
      />
      <FAQItem
        question="What is the bonus question?"
        answer="We will ask you to predict a stat from the game, ex. Predict the total runs scored in the match?"
        animationDelayValue="0.8"
      />
      <FAQItem
        question="(Prior to IPL 2022) How many points for correct predcition?"
        answer="Each right prediction will get you 1 point. For every match we ask two questions, so you can score 2 points per match. No negative points."
        animationDelayValue="0.8"
      />
      <FAQItem
        question="(From IPL 2022) How many points for correct predcition?"
        answer="Points = Total Votes for all options / Number of Correct Predictions.
        
        We are introducing dynamic odds since 2022 season. This will reward high risk bets with more points and less risky bets with less points."
        animationDelayValue="0.8"
      />
      <FAQItem
        question="(From IPL 2023) Are there any negative poitns?"
        answer="Yes! From IPL 2023, we introduced negative points as way to also introduce a loss to the points tally in case you lose."
        animationDelayValue="0.9"
      />
      <FAQItem
        question="(From ODI WC 2023) Can I vote again (Revote)?"
        answer="Yes! From ODI WC 2023, we introduced revotes! After a certain interval after the initial votes are captured, you will be alerted with a message asking for any revotes, and these revotes will be captured shortly after. This now allows people who had voted earlier to change their votes, also people who didnt vote first time or missed voting to cast their votes."
        animationDelayValue="0.9"
      />
      <FAQItem
        question="Is revote scoring same as initial vote?"
        answer="No! If you win via Revote, it gives you lesser points than if you had voted initially. The scenario if you revote and loose depends on if you had voted earlier. If you had voted the first time and had the winning option, but you switched to a loosing option the -ve penalty is higher. If you didnt vote the 1st time and are casting the vote first time via revote, you dont get the higher penalty and usual negative points scoring applies."
        animationDelayValue="0.9"
      />
      <FAQItem
        question="How are negative points calculated?"
        answer="Same way as correct points are calculated, but in negative. Whatever points you could win are instead added as negative points to the tally."
        animationDelayValue="0.9"
      />
      <FAQItem
        question="How to predict?"
        answer="A bot will ask you questions before every match, and will add possible answers as emojis. Just click on the emoji and your vote is logged."
        animationDelayValue="1"
      />
      <FAQItem
        question="Can you choose multiple answers?"
        answer="Multiple votes wont count, you have to choose only one answer for every question."
        animationDelayValue="1.1"
      />
      <FAQItem
        question="How long do I have before voting closes?"
        answer="Voting begins as soon as bot announces the question, which happens 75mins before a match starts. Voting ends as soon as the match starts. The bot will also post a message to indicate voting is closed."
        animationDelayValue="1.2"
      />
      <FAQItem
        question="Where can I see the leader board?"
        answer="The top positions of the Leader board are announced every morning, however you can view the leader board in real time on https://iplpredictionpro.axelerant.com/. It gets updated as soon as a match is over."
        animationDelayValue="1.3"
      />
      <FAQItem
        question="Can I participate via the website?"
        answer="The website is only a live dashboard, it will only show the results. The only way to participate is via Slack."
        animationDelayValue="1.4"
      />
      <FAQItem
        question="Who runs this game?"
        answer="This game is being run by bots and various integration, they are responsible for asking questions and collecting answers. Only Human intervention is to input the winning team after every match."
        animationDelayValue="1.5"
      />
    </div>
  );
}

export default FAQ;
