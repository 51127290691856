import React from 'react';
import MyPlot from './plot';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { getGoogleDoc, checkCacheTS, setLastUpdatedComponent } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import Loading from '../../src/utils/loading';

class ProgressChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: true,
      results: null,
      toggleLegendsState: false,
    }
  }

  componentDidMount() {
    const { savedTournament } = this.props;

    // Check Local Storage.
    const cacheStatus = checkCacheTS('progression_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('progression');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        this.setState({
          results: resultsUnwrap,
          wait: false,
        });
        setLastUpdatedComponent(localStorage.getItem('progression_last_updated'));
      }
    } else {
      getGoogleDoc('Progression', savedTournament).then((response) => {
        this.setState({
          results: response,
          wait: false,
        });
        // Local Storage set.
        localStorage.setItem('progression', stringify(response));
        localStorage.setItem('progression_last_updated', Date.now());
        setLastUpdatedComponent(localStorage.getItem('progression_last_updated'));
      });
    }
  }

  getRandomColor = () => {
    const color = "#xxxxxx".replace(/x/g, y=>(Math.random()*16|0).toString(16));
    return color;
  };

  getCleanData = (results) => {
    // Initialize with one empty.
    let nameSeries = [{
      label: 'Match #',
    }];
    let dataSeries = [];
    let dataKey = [];

    Object.entries(results).forEach(([key, result]) => {
      if (result.data) {
        var trendLineColor = this.getRandomColor();
        var userProgression = result.data.length > 1 ? result.data.split(',') : result.data.split('');
        userProgression.unshift(parseInt(0));
        // Check if user has something to plot.
        if (parseInt(userProgression[userProgression.length -1]) > 0) {
          // Name.
          // Width makes the line thicker.
          nameSeries.push({
            label: result.name,
            stroke: trendLineColor,
            width: 4,
            dash: [0],
            // spanGaps: false,
            // fill: trendLineColor,
          });
          // Data points for each user.
          dataSeries.push(
            userProgression
          );
        }
      }
    });

    if (dataSeries.length > 0) {
      // Add index as key.
      for (let index = 0; index < dataSeries[0].length; index++) {
        dataKey.push(parseInt(index));
      }

      // Add dataKey as first element in data[].
      dataSeries.unshift(dataKey);

      return ({
        nameSeries: nameSeries,
        dataSeries: dataSeries,
      });
    }

    return false;
  };

  toggleLegends = (e) => {
    const { toggleLegendsState } = this.state;
    document.querySelectorAll('.u-legend .u-series th').forEach(function(legend) {
      legend.click();
    });
    this.setState({
      toggleLegendsState: !toggleLegendsState,
    });
  };

  render() {
    const {
      wait,
      results,
    } = this.state;

    const { colorMode } = this.props;

    if (wait === true) {
      return (
        <div className="chart-waiting trendlines">
          <Loading />
        </div>
      );
    }

    const dataPoints = this.getCleanData(results);
    const defaultColor = colorMode === true ? '#222' : '#57b8ff';

    if (!dataPoints) {
      return null;
    }

    let data = dataPoints.dataSeries;

    const opts = {
      width: 1000,
      height: 600,
      title: "Trendlines (Points progression)",
      scales: {
        x: {
          time: false,
          // snap x-zoom to exact data values
          range: (u, min, max) => [
            data[0][u.valToIdx(min)],
            data[0][u.valToIdx(max)],
          ],
        },
      },
      hooks: {
        drawSeries: [
          (u, si) => {
            let ctx = u.ctx;
            ctx.save();
            let s  = u.series[si];
            let xd = u.data[0];
            let yd = u.data[si];

            let [i0, i1] = s.idxs;

            let x0 = u.valToPos(xd[i0], 'x', true);
            let y0 = u.valToPos(yd[i0], 'y', true);
            let x1 = u.valToPos(xd[i1], 'x', true);
            let y1 = u.valToPos(yd[i1], 'y', true);

            const offset = (s.width % 2) / 2;

            ctx.translate(offset, offset);

            ctx.beginPath();
            ctx.strokeStyle = s._stroke;
            // Removes the dotted line on each trendline.
            ctx.setLineDash([0, 5]);
            ctx.moveTo(x0, y0);
            ctx.lineTo(x1, y1);
            ctx.stroke();

            ctx.translate(-offset, -offset);
          }
        ]
      },
      series: dataPoints.nameSeries,
      axes: [
        {
          show: true,
          label: "Match #",
          labelSize: 20,
          labelFont: "bold 12px Quicksand",
          font: "12px Quicksand",
          stroke: defaultColor,
          ticks: {
            stroke: defaultColor,
          }
        },
        {
          show: true,
          label: "Points",
          labelSize: 20,
          labelFont: "bold 12px Quicksand",
          font: "12px Quicksand",
          stroke: defaultColor,
          ticks: {
            stroke: defaultColor,
          }
        }
      ]
    };

    return (
      <React.StrictMode>
        <div className="toggle-legend">
          <Toggle
            id='toggle-legends'
            defaultChecked={this.state.toggleLegendsState}
            onChange={this.toggleLegends}
          />
          <label htmlFor='toggle-legends'>Toggle All Trendlines</label>
        </div>
        <div className="trendlines a-chart">
          <MyPlot options={opts} data={data} />
        </div>
      </React.StrictMode>
    );
  }
}

export default ProgressChart;
