import React from 'react';
import UserTitleStats from './user-title-stats';

const getNameBanner = (imageurl, name, matchpoints, bonuspoints, totalmatch, loss, accuracy, totalpoints, triviapoints, rank) => {
  if (imageurl) {
    return (
      <div className="name-image">
        <div className="user-image"><img loading="lazy" src={imageurl} alt={`${name}`}/></div>
        <h1>{name}</h1>
        <UserTitleStats
          matchpoints={matchpoints}
          bonuspoints={bonuspoints}
          totalmatch={totalmatch}
          loss={loss}
          accuracy={accuracy}
          totalpoints={totalpoints}
          triviapoints={triviapoints}
          rank={rank}
        />
      </div>
    );
  }

  return (
    <div className="name-image">
      <div className="user-image no-image"><span>{name.charAt(0)}</span></div>
      <h1>{name}</h1>
      <UserTitleStats
        matchpoints={matchpoints}
        bonuspoints={bonuspoints}
        totalmatch={totalmatch}
        loss={loss}
        totalpoints={totalpoints}
        triviapoints={triviapoints}
        rank={rank}
      />
    </div>
  );
};

const UserTitle = (props) => {
  const {
    name,
    imageurl,
    matchpoints,
    bonuspoints,
    totalmatch,
    loss,
    accuracy,
    totalpoints,
    triviapoints,
    rank,
  } = props;

  return (
    <div className="user-title-stats">
      { getNameBanner(imageurl, name, matchpoints, bonuspoints, totalmatch, loss, accuracy, totalpoints, triviapoints, rank) }
    </div>
  );
};

export default UserTitle;
