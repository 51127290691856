import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './offline.css';

const MessageBanner = ({ children, classes }) => {
  return (
    <div className={`help-text ${classes}`}>
      <FontAwesomeIcon icon={faInfoCircle} />
      {children}
    </div>
  );
};

export default MessageBanner;
