import React from 'react';
import UserTitle from '../user-title';
import './user-header.css';
import UserStatsDial from '../charts/win-ratio-small';
import UserProgressChart from '../charts/userProgression';

const UserHeader = ({ colorMode, user, schedule }) => {
  const {
    name,
    imageurl,
    matchpoints,
    bonuspoints,
    totalmatch,
    ranking,
    loss,
    accuracy,
    triviapoints,
    totalpoints,
    progression
  } = user;

  return (
    <div className="user-header-wrapper">
      <div className="user-header">
        <UserTitle
          name={name}
          imageurl={imageurl}
          bonuspoints={bonuspoints}
          matchpoints={matchpoints}
          totalmatch={totalmatch}
          totalpoints={totalpoints}
          triviapoints={triviapoints}
          loss={loss}
          accuracy={accuracy}
          rank={ranking}
        />
        <UserStatsDial
          bonusPoints={bonuspoints}
          matchPoints={matchpoints}
          totalMatch={totalmatch}
          colorMode={colorMode}
          accuracy={accuracy}
          totalpoints={totalpoints}
        />
        <div className="user-progress-chart">
          <UserProgressChart name={name} colorMode={colorMode} progressionData={progression} />
        </div>
      </div>
    </div>
  )
};

export default UserHeader;
