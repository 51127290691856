import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faChevronCircleRight, faUser, faStar } from '@fortawesome/free-solid-svg-icons';

const BonusQuestion = ({ bonusUnwrap, bonusUserAnswer, bonusPoints }) => {
  let bonusStar = '';
  let bonusClass = ''
  if (bonusUserAnswer === bonusUnwrap.answer 
    || bonusUserAnswer === parseInt(bonusUnwrap.answer)) {
    bonusStar = <FontAwesomeIcon title="Correct Answer" icon={faStar} />;
    bonusClass = 'win';
  }

  return (
    <div className="bonus-question">
      <div className="question">
        <FontAwesomeIcon title="Question" icon={faQuestionCircle} />
        <span>{bonusUnwrap.question}</span>
      </div>
      <div className="answer">
        <FontAwesomeIcon title="Correct Answer" icon={faChevronCircleRight} />
        {bonusUnwrap.answer}
      </div>
      <div className={`user-answer ${bonusClass}`}>
        <FontAwesomeIcon title="Your Prediction" icon={faUser} />
        <span>{bonusUserAnswer === 'A' ? 'Absent' : bonusUserAnswer}</span>
        {bonusStar}
        {bonusPoints && bonusPoints.length > 0 &&
          <span className="b skew highlight">{bonusPoints}</span>
        }
      </div>
    </div>
  );
};

export default BonusQuestion;
