import React from 'react';
import { getGoogleDoc, checkCacheTS, getTournamentLogo } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import Loading from '../utils/loading';
import { PredictionCard } from './prediction-card';

class PredictionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      wait: true,
    };
  }

  componentDidMount() {
    const { savedTournament, setScheduleCallback } = this.props;
    // Check Local Storage.
    const cacheStatus = checkCacheTS('schedule_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('schedule');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        this.setState({
          schedule: resultsUnwrap,
          wait: false,
        });
        setScheduleCallback(resultsUnwrap);
      }
    } else {
      getGoogleDoc('Schedule', savedTournament).then((response) => {
        this.setState({
          schedule: response,
          wait: false,
        });
        setScheduleCallback(response);
        // Local Storage set.
        localStorage.setItem('schedule', stringify(response));
        localStorage.setItem('schedule_last_updated', Date.now());
      });
    }
  }

  getPredictionCards = (userRow, schedule) => {
    const matchList = [];
    const { savedTournament } = this.props;
    let matchSequence = [];

    if (userRow.matchpointssequence !== undefined) {
      matchSequence = userRow.matchpointssequence.split(',');
    }

    if (userRow.predictions !== undefined && userRow.bonuspredictions !== undefined) {
      const history = JSON.parse(userRow.predictions);
      const bonusHistory = JSON.parse(userRow.bonuspredictions);
      // Assumption is we will get the same number of predictions as matches happened.
      // Even if user has not played a match, we have to pass something. Maybe 'absent' or 'NP'.
      let count = 0;
      const logoClass = getTournamentLogo(savedTournament);
      for (const [key, value] of Object.entries(history)) {
        // Just to make sure keys are same, this should never fail ideally.
        if (key.toString() === schedule[count].matchkey) {
          matchList.push(
            <PredictionCard
              logoClass={logoClass}
              prediction={value}
              winner={schedule[count].result}
              key={schedule[count].matchnumber}
              team1={schedule[count].team1_flag}
              team2={schedule[count].team2_flag}
              team1name={schedule[count].team1_name}
              team2name={schedule[count].team2_name}
              number={schedule[count].matchnumber}
              bonusQuestion={schedule[count].bonusquestion}
              bonusUserAnswer={bonusHistory[schedule[count].matchkey]}
              bonusPoints={schedule[count].bonuspoints}
              matchPoints={schedule[count].matchpoints}
              losingMatchPoints={schedule[count].losingmatchpoints}
              matchpointSequence={matchSequence.length > 0 ? matchSequence[count] : null}
            />,
          );
        }
        count++;
      }
    } else {
      // User has played no match yet.
      matchList.push(
        <div className="page-title-block">No Prediction history yet, results should show as soon as first match of IPL 2021 is complete.</div>
      );
    }

    // Reverse the order so the latest match is displayed first.
    return matchList.reverse();
    // return matchList;
  }

  render() {
    const {
      wait,
      schedule,
    } = this.state;

    const { userRow } = this.props

    if (wait === true) {
      return (
        <div className="predictions-waiting">
          <Loading />
        </div>
      )
    }

    return (
      <div className="predictions">
        {this.getPredictionCards(userRow, schedule)}
      </div>
    )
  }
}
export default PredictionHistory;
