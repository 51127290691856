import React from 'react';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import '../utils/tooltip.css';

const covertToPercentage = (data, max) => {
  return (data/max * 100).toFixed(1);
};

const UserStatsDial = ({ bonusPoints, matchPoints, totalMatch, colorMode, accuracy }) => {
  var series = [
    covertToPercentage(matchPoints, parseFloat(matchPoints) + parseFloat(bonusPoints)),
    covertToPercentage(bonusPoints, parseFloat(matchPoints) + parseFloat(bonusPoints)),
    accuracy,
  ];

  const defaultColor = colorMode === true ? '#c79d94' : '#0c1b33cc';
  const defaultLabelColor = colorMode === true ? '#333' : '#57b8ff';
  const dialColors = colorMode === true ? ["#fc4c02", "#8a2be2", "#57b8ff"] : ["#fc4c02", "#ddd92a", "#57b8ff"];

  var options = {
    chart: {
      height: 300,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 5,
          size: "40%"
        },
        track: {
          show: true,
          background: defaultColor,
          strokeWidth: '97%',
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        },
        dataLabels: {
          name: {
            fontSize: '18px',
            fontFamily: 'Quicksand',
            fontWeight: 600,
            color: defaultLabelColor,
          },
          value: {
            fontSize: '18px',
            fontFamily: 'Quicksand',
            fontWeight: 600,
            color: defaultLabelColor,
          },
          total: {
            show: true,
            label: 'Total Points',
            fontSize: '14px',
            fontFamily: 'Quicksand',
            fontWeight: 600,
            color: defaultLabelColor,
            formatter: function () {
              return (parseFloat(bonusPoints) + parseFloat(matchPoints)).toFixed(2);
            }
          }
        }
      }
    },
    stroke: {
      lineCap: "round",
    },
    colors: dialColors,
    labels: ['Won/Total', 'Bonus/Total', 'Win %'],
  };

  return (
    <div className="stats-dial">
      <Chart
        series={series}
        options={options}
        type="radialBar"
        width="300"
        height="300"
      />
      <div className='info-popup'>
        <FontAwesomeIcon title="User dial" icon={faQuestionCircle} />
        <div className="caption">
          <b>Total Points</b> = Win points + Bonus points <br />
          <b>Win %</b> = Prediction Accuracy, each match has 2 predictions<br/>
          <b>Bonus/Total</b> = Bonus points out of total points.<br />
          <b>Won/Total</b> = Win points out of total points.<br />
          <b>Trivia points</b> = Cricket Trivia points are not included in this chart. We only consider Prediction pro points.<br />
        </div>
      </div>
    </div>
  );
};

export default UserStatsDial;
