import React from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faInfoCircle, faChartBar, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  return (
    <>
      <div className="nav-bar">
        <NavLink
          className="nav-results"
          activeClassName="is-active"
          to="/"
          exact
        >
          <FontAwesomeIcon icon={faHome} />
          <span>Leaderboard</span>
        </NavLink>
        <NavLink
          className="nav-schedule"
          activeClassName="is-active"
          to="/schedule"
          exact
        >
          <FontAwesomeIcon icon={faCalendarAlt} />
          <span>Schedule</span>
        </NavLink>
        <NavLink
          className="nav-charts"
          activeClassName="is-active"
          to="/charts"
          exact
        >
          <FontAwesomeIcon icon={faChartBar} />
          <span>Charts</span>
        </NavLink>
        <NavLink
          className="nav-about"
          activeClassName="is-active"
          to="/about"
          exact
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>About Us</span>
        </NavLink>
        <NavLink
          className="nav-faq"
          activeClassName="is-active"
          to="/faq"
          exact
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>FAQ</span>
        </NavLink>
      </div>
    </>
  );
};

export default NavBar;
