import React from 'react';
import '../schedule/match-card.css';
import './prediction.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import BonusQuestion from './bonus-question';

export const getBonusQuestionAnswer = (bonusQuestion) => {
  let response = '';
  let splitRaw = JSON.parse(bonusQuestion);
  Object.keys(splitRaw).map((key) => {
    response = {
      'question': key,
      'answer': splitRaw[key],
    };
    return response;
  });
  return response;
}

export const getTeamClass = (team, teamname, winner, prediction) => {
  let teamClasses = '';
  if (team === winner || teamname === winner) {
    teamClasses += 'win';
  }

  if (team === prediction || teamname === prediction) {
    teamClasses += ' prediction';
  }

  return teamClasses;
};

export const PredictionCard = (props) => {
  const {
    team1,
    team2,
    team1name,
    team2name,
    winner,
    prediction,
    number,
    bonusQuestion,
    bonusUserAnswer,
    logoClass,
    bonusPoints,
    matchPoints,
    losingMatchPoints,
    matchpointSequence,
  } = props;

  let score='';
  let absentClass= '';
  let bonusClass= '';

  const bonusUnwrap = getBonusQuestionAnswer(bonusQuestion);
  if (bonusUnwrap.answer === bonusUserAnswer) {
    bonusClass = 'bonus-correct';
  }

  // This means user has correctly guessed the winner.
  if (winner === prediction) {
    score = 'wwcd';
  }

  // This means user has missed the prediction.
  if (prediction === 'absent') {
    absentClass += 'absent';
  }

  // This means match result is not yet ready.
  if (winner === undefined) {
    absentClass += ' waiting-result';
  }
  
  const team1Classes = getTeamClass(team1, team1name, winner, prediction);
  const team2Classes = getTeamClass(team2, team2name, winner, prediction);

  const losingMatchPointsSantinised = losingMatchPoints > 0 ? -losingMatchPoints : null;

  if (matchpointSequence !== null) {
    return (
      <div className={`prediction-card animate__animated animate__bounceIn ${score} ${absentClass} ${bonusClass}`}>
        {/* Match Number */}
        <span className="number">{number}</span>
        {/* Team 1 */}
        <div className={team1Classes}>
          <div className="team-name-icon">
            <span className={`${logoClass} ${team1} team-icon`}/>
            <span>{team1.toUpperCase()}</span>
          </div>
        </div>
        {/* Team 2 */}
        <div className={team2Classes}>
          <div className="team-name-icon">
            <span className={`${logoClass} ${team2} team-icon`}/>
            <span>{team2.toUpperCase()}</span>
          </div>
        </div>
        <div className="match-points"><span className="w skew highlight">{matchpointSequence}</span></div>
        <BonusQuestion
          bonusUnwrap={bonusUnwrap}
          bonusUserAnswer={bonusUserAnswer}
          bonusPoints={bonusPoints}
        />
      </div>
    );
  }

  return (
    <div className={`prediction-card animate__animated animate__bounceIn ${score} ${absentClass} ${bonusClass}`}>
      {/* Match Number */}
      <span className="number">{number}</span>
      {/* Team 1 */}
      <div className={team1Classes}>
        <div className="team-name-icon">
          <span className={`${logoClass} ${team1} team-icon`}/>
          <span>{team1.toUpperCase()}</span>
        </div>
        <div className="icons">
          {team1Classes.search('win') > -1 &&
            <>
              <span className="w skew highlight">{matchPoints}</span>
              {/* <FontAwesomeIcon title="Team Win" icon={faTrophy} /> */}
            </>
          }
          {team1Classes.search('prediction') > -1 &&
            <>
              <FontAwesomeIcon title="Your Prediction" icon={faBullseye} />
              {/* Adding the minus points in prediction card */}
              {losingMatchPointsSantinised !== null &&
                <span className="minus skew highlight">{losingMatchPointsSantinised}</span>
              }
            </>
          }
        </div>
      </div>
      {/* Team 2 */}
      <div className={team2Classes}>
        <div className="team-name-icon">
          <span className={`${logoClass} ${team2} team-icon`}/>
          <span>{team2.toUpperCase()}</span>
        </div>
        <div className="icons">
          {team2Classes.search('win') > -1 &&
            <>
              <span className="w skew highlight">{matchPoints}</span>
              {/* <FontAwesomeIcon title="Team Win" icon={faTrophy} /> */}
            </>
          }
          {team2Classes.search('prediction') > -1 &&
            <>
              <FontAwesomeIcon title="Your Prediction" icon={faBullseye} />
              {losingMatchPointsSantinised !== null &&
                <span className="minus skew highlight">{losingMatchPointsSantinised}</span>
              }
            </>
          }
        </div>
      </div>
      <BonusQuestion
        bonusUnwrap={bonusUnwrap}
        bonusUserAnswer={bonusUserAnswer}
        bonusPoints={bonusPoints}
      />
    </div>
  );
};
