import React from 'react';
import MyPlot from './plot';
// import { getGoogleDoc, checkCacheTS, setLastUpdatedComponent } from '../utils/apiHelper';
// import { parse, stringify } from 'flatted';
// import Loading from '../../src/utils/loading';

class UserProgressChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: true,
    }
  }

  getRandomColor = () => {
    const color = "#xxxxxx".replace(/x/g, y=>(Math.random()*16|0).toString(16));
    return color;
  };

  getCleanData = (progressionData) => {
    const { name } = this.props;
    // Initialize with one empty.
    let nameSeries = [{
      label: 'Match #',
    }];
    let dataSeries = [];
    let dataKey = [];

    let trendLineColor = this.getRandomColor();
    if (progressionData === undefined) {
      return false;
    }

    let userProgression = progressionData.length > 1 ? progressionData.split(',') : progressionData.split('');
    userProgression.unshift(parseInt(0));

    // Check if user has something to plot.
    if (!isNaN(parseInt(userProgression[userProgression.length -1]))) {
      // Name.
      // Width makes the line thicker.
      nameSeries.push({
        label: name,
        stroke: trendLineColor,
        width: 4,
        dash: [0],
        // spanGaps: false,
        // fill: trendLineColor,
      });
      // Data points for each user.
      dataSeries.push(
        userProgression
      );
    }

    if (dataSeries.length > 0) {
      // Add index as key.
      for (let index = 0; index < dataSeries[0].length; index++) {
        dataKey.push(parseInt(index));
      }

      // Add dataKey as first element in data[].
      dataSeries.unshift(dataKey);

      return ({
        nameSeries: nameSeries,
        dataSeries: dataSeries,
      });
    }

    return false;
  };

  render() {
    // const {
    //   wait,
    //   results,
    // } = this.state;

    const {
      progressionData,
      colorMode,
    } = this.props;

    // if (wait === true) {
    //   return (
    //     <div className="chart-waiting trendlines">
    //       <Loading />
    //     </div>
    //   );
    // }

    const dataPoints = this.getCleanData(progressionData);
    const defaultColor = colorMode === true ? '#222' : '#57b8ff';

    if (!dataPoints) {
      return null;
    }

    let data = dataPoints.dataSeries;

    const opts = {
      width: 400,
      height: 375,
      title: "Journey",
      scales: {
        x: {
          time: false,
          // snap x-zoom to exact data values
          range: (u, min, max) => [
            data[0][u.valToIdx(min)],
            data[0][u.valToIdx(max)],
          ],
        },
      },
      hooks: {
        drawSeries: [
          (u, si) => {
            let ctx = u.ctx;
            ctx.save();
            let s  = u.series[si];
            let xd = u.data[0];
            let yd = u.data[si];

            let [i0, i1] = s.idxs;

            let x0 = u.valToPos(xd[i0], 'x', true);
            let y0 = u.valToPos(yd[i0], 'y', true);
            let x1 = u.valToPos(xd[i1], 'x', true);
            let y1 = u.valToPos(yd[i1], 'y', true);

            const offset = (s.width % 2) / 2;

            ctx.translate(offset, offset);

            ctx.beginPath();
            ctx.strokeStyle = s._stroke;
            // Removes the dotted line on each trendline.
            ctx.setLineDash([0, 5]);
            ctx.moveTo(x0, y0);
            ctx.lineTo(x1, y1);
            ctx.stroke();

            ctx.translate(-offset, -offset);
          }
        ]
      },
      series: dataPoints.nameSeries,
      axes: [
        {
          show: true,
          label: "Match #",
          labelSize: 20,
          labelFont: "bold 12px Quicksand",
          font: "12px Quicksand",
          stroke: defaultColor,
          ticks: {
            stroke: defaultColor,
          }
        },
        {
          show: true,
          label: "Points",
          labelSize: 20,
          labelFont: "bold 12px Quicksand",
          font: "12px Quicksand",
          stroke: defaultColor,
          ticks: {
            stroke: defaultColor,
          }
        }
      ]
    };

    return (
      <React.StrictMode>
        <div className="trendlines a-chart">
          <MyPlot options={opts} data={data} />
        </div>
      </React.StrictMode>
    );
  }
}

export default UserProgressChart;