import React from 'react';
import './last-updated.css'

const LastUpdated = () => {
  return (
    <div className="last-updated" title="This data was last synced at:">
      <span id="last-updated-value">Loading ...</span>
    </div>
  );
};

export default LastUpdated;
