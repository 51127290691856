import React from 'react';

const Avatar = ({name, imageurl}) => {
  let avatar = '';
  // If app is offline, show only names.
  if (navigator.onLine === false) {
    avatar = <div className="user-image no-image"><span>{name.charAt(0)}</span></div>;
  } else {
    if (imageurl) {
      avatar = <div className="user-image"><img loading="lazy" src={imageurl} alt={`${name}`}/></div>;
    } else {
      avatar = <div className="user-image no-image"><span>{name.charAt(0)}</span></div>;
    }
  }

  return avatar;
};

export default Avatar;