import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './result-individual.css';
import { getGoogleDoc, checkCacheTS, setLastUpdatedComponent } from '../utils/apiHelper';
import { parse, stringify } from 'flatted';
import Loading from '../utils/loading';
import PredictionHistory from './predictionHistory';
import UserHeader from '../user-header';

const ResultIndividualUser = ({ colorMode, savedTournament }) => {
  const [request, setRequest] = useState({
    user: [],
    schedule: [],
    wait: true,
  });

  const [schedule, setSchedule] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    // Check Local Storage.
    const cacheStatus = checkCacheTS('individual_last_updated');
    if (cacheStatus === 'toosoon') {
      const cachedResults = localStorage.getItem('individual');
      if (cachedResults) {
        const resultsUnwrap = parse(cachedResults);
        resultsUnwrap.forEach((value) => {
          if (value.playerkey === id.toString() || value.ranking + value.slackuid === id.toString()) {
            setRequest({
              user: value,
              wait: false,
            });
            setLastUpdatedComponent(localStorage.getItem('individual_last_updated'));
          }
          return false;
        });
      }
    } else {
      getGoogleDoc('Individual', savedTournament).then((response) => {
        response.forEach((value) => {
          if (value.playerkey === id.toString() || value.ranking + value.slackuid === id.toString()) {
            setRequest({
              user: value,
              wait: false,
            });
          }
        });

        localStorage.setItem('individual', stringify(response));
        localStorage.setItem('individual_last_updated', Date.now());
        setLastUpdatedComponent(localStorage.getItem('individual_last_updated'));
        return false;
      });
    }
  }, [id, savedTournament]);

  if (request.wait === true) {
    return (
      <div className="ipl-prediction-leaderboard-individual">
        <div className="page-title-block">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <div className="ipl-prediction-leaderboard-individual">
      <UserHeader colorMode={colorMode} user={request.user} schedule={schedule} />
      <PredictionHistory savedTournament={savedTournament} userRow={request.user} setScheduleCallback={setSchedule}/>
    </div>
  )

};

export default ResultIndividualUser;
