import React from 'react';
import './about-us.css';
import TeamMember from './member';

const AboutUs = () => {

  return (
    <>
      <div className="animate__animated animate__fadeIn">
        <b>We are Axelerant @ axelerant.com !!</b>
        <p>Axelerant IPL Prediction Pro is the prediction game for the T20 cricket league (Indian Premier League) exclusively designed for cricket fans at Axelerant and to engage them with the sport they love deeply.</p>
        <p>IPL Prediction Pro is a Slack Bot that allows users to predict the winning team, predict scores, and many other things to become an ultimate IPL Prediction Pro. Slack bots interpret, trigger responses, and communicates with the users on Slack.</p>
        <p>The website is a fully decoupled website created using React for displaying statistics of the predictions made by the Axelerant team members. Other technologies used to build this application are Google Sheets, Google Sheets API, uPlot Chart plotter, ApexCharts, Google App Script, Zapier, and Slack Bot.</p>
      </div>
      <div className="team-excerpt animate__animated animate__fadeIn">
        <h2>IPL Prediction Pro Team</h2>
        <div className="members">
          <TeamMember
            name="Prateek Jain"
            photo="https://d1xmlzncnovrpo.cloudfront.net/sites/default/files/public/styles/large/public/prateek-jain.jpg?itok=xsbzebg9"
            linkedin="https://www.linkedin.com/in/prateekjain87/"
            animateClass="animate__zoomInLeft"
          />
          <TeamMember
            name="Swarad Mokal"
            photo="https://d1xmlzncnovrpo.cloudfront.net/sites/default/files/public/styles/large/public/swarad-mokal.jpg?itok=xXDspIsJ"
            linkedin="https://www.linkedin.com/in/swarad/"
            animateClass="animate__zoomInRight"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
