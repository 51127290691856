import React from 'react';
import './match-card.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faCity } from '@fortawesome/free-solid-svg-icons';

const getStyleClasses = (time, date) => {
  let cardClass = '';
  let numberClass = '';
  // Check for past time.
  const matchTime = Date.parse(`${time}, ${date}`);
  let dateObj = new Date();
  if (matchTime < dateObj.getTime()) {
    cardClass = 'past';
  } else {
    // Check for today.
    const matchDate = new Date(date);
    const todaysDate = new Date();

    if(matchDate.setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0)) {
      cardClass += ' today';
      numberClass = 'animate__animated animate__flash animate__infinite animate__slow';
    }
  }

  return {
    cardClass: cardClass,
    numberClass: numberClass,
  };
};

const MatchCard = (props) => {
  const {
    city,
    date,
    matchNumber,
    matchName,
    time,
    venue,
    team1,
    team2,
    won,
    logoClass
  } = props;

  const getTimeClasses = getStyleClasses(time, date);

  return (
    <div className={`schedule-card animate__animated animate__bounceIn ${getTimeClasses.cardClass}`}>
      <div className={`number ${getTimeClasses.numberClass}`}>{matchNumber}</div>
      <div className="flag-calender">
        <div className="flag">
          <span className={`${logoClass} ${team1} ${team1 === won ? 'won' : ''}`} /> vs <span className={`${logoClass} ${team2} ${team2 === won ? 'won' : ''}`} />
        </div>
      </div>
      <div className="match-name">{matchName}</div>
      <div className="match-time">
        <FontAwesomeIcon icon={faClock} />
        <span>{`${time}, ${moment(date).format('dddd')}, ${moment(date).format("DD MMMM")}`}</span>
      </div>
      <div className="match-venue">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span>{`${venue}`}</span>
      </div>
      <div className="match-venue city">
        <FontAwesomeIcon icon={faCity} />
        <span>{`${city}`}</span>
      </div>
    </div>
  );
};



export default MatchCard;
