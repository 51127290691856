import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import ReactGA from 'react-ga4';

// // Google Analytics.
// ReactGA.initialize('G-WKDS0V1DHR'); // add your tracking id here.
// // ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
