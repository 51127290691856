import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const FAQItem = ({ question, answer, animationDelayValue }) => (
  <div style={{animationDelay: `${animationDelayValue}s`}} className="faq-item animate__animated animate__fadeInUp">
    <div className="question">
      <FontAwesomeIcon icon={faQuestionCircle} />
      {question}
    </div>
    <div className="answer">
      <FontAwesomeIcon icon={faArrowRight} />
      {answer}
    </div>
  </div>
);

export default FAQItem;
