import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import './sun-moon.css';

const toggleSunMoon = 'darkModeCheckbox'

class SunMoon extends React.Component {
  componentDidMount() {
    // On page load.
    const status = this.getStatus();
    if (status) {
      document.getElementsByTagName('body')[0].classList.remove('dark-mode');
    } else {
      document.getElementsByTagName('body')[0].classList.add('dark-mode');
    }
  }

  toggle = (e) => {
    const value = e.target.checked;
    // Checked = Light mode.
    if (value === true) {
      // alert('remove class');
      document.getElementsByTagName('body')[0].classList.remove('dark-mode');
      localStorage.setItem(toggleSunMoon, 'light');
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#c79d94');
    }
    // unChecked = Dark mode.
    if (value === false) {
      // alert('add class');
      document.getElementsByTagName('body')[0].classList.add('dark-mode');
      localStorage.setItem(toggleSunMoon, 'dark');
      document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#462255');
    }
    // Fire event.
    const event = new CustomEvent('colorModeChanged', {
      detail: value,
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    document.dispatchEvent(event);
  };

  getStatus = () => {
    const value = localStorage.getItem(toggleSunMoon);
    if (value === 'light') {
      return true;
    } else {
      return false;
    }
  };

  getLabel = () => {
    if(this.getStatus()) {
      return <FontAwesomeIcon icon={faSun} />;
    } else {
      return <FontAwesomeIcon icon={faMoon} />;
    }
  };

  render () {
    const status = this.getStatus();

    return (
      <div className="sun-moon">
        <input
          type="checkbox"
          id="toggle--sunmoon"
          className="toggle--sunmoon"
          defaultChecked={status}
          onChange={(e) => this.toggle(e)}
        />
        <label className="toggle--sunmoon-btn" htmlFor="toggle--sunmoon">
          {this.getLabel()}
        </label>
      </div>
    );
  }
}

export default SunMoon;
