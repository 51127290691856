import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faStar, faTrophy, faFire, faIdBadge, faCrown, faFrown, faMedal, faCalculator } from '@fortawesome/free-solid-svg-icons';

const HeaderRow = () => {
  return (
    <div className="header results-row">
      <div className="user-avatar" />
      <div className="name">
        <FontAwesomeIcon icon={faIdBadge} />
        <span>Name</span>
      </div>
      <div className="item form" title="Your last five matches, from Right to Left. Right most is the latest match result.">
        <FontAwesomeIcon icon={faArrowRight} />
        <span>Last 5</span>
      </div>
      {/* <div className="played" title="Played">Played</div> */}
      <div className="item won" title="Correct Match Predictions.">
        <FontAwesomeIcon icon={faCrown} />
        <span>Won</span>
      </div>
      <div className="item lost" title="Incorrect Match predictions.">
        <FontAwesomeIcon icon={faFrown} />
        <span>Lost</span>
      </div>
      <div className="best-streak" title="Best Overall Win streak, consecutive wins.">
        <FontAwesomeIcon icon={faFire} />
        <span>Streak</span>
      </div>
      <div className="win-percentage" title="You Prediction accuracy, each match has two predictions.">
        <FontAwesomeIcon icon={faCalculator} />
        <span>Win%</span>
      </div>
      <div className="bonus" title="Bonus points, maximum 1 bonus question per match.">
        <FontAwesomeIcon icon={faStar} />
        <span>Bonus</span>
      </div>
      <div className="total-points" title="Total = Won + Bonus">
        <FontAwesomeIcon icon={faTrophy} />
        <span>Total</span>
      </div>
      <div className="position" title="Rank">
        <FontAwesomeIcon icon={faMedal} />
        <span>Position</span>
      </div>
    </div>
  )
};

export default HeaderRow;
